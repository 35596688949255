<template>
  <b-container fluid="lg">

    <!-- modal entregar URL -->
    <b-modal
        hide-header-close
        v-model="ModalEntregaURL"
        persistent
        no-close-on-esc
        title="Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            <span class="my-2">¿ Estás seguro de entregar este material a manera de URL ?</span>

            <b-form-input
                class="my-2"
                id="filter-input"
                v-model="URLaEntregar"
                type="text"
                placeholder="Coloque aquí la URL del archivo (ejemplo http://www.ejemplo.com/ "
            >
            </b-form-input>

          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalEntregaURL()"
          >
            Cancelar
          </b-button>
          <b-button
              variant="success"
              size="sm"
              class="float-right mx-2"
              @click="entregarURL()"
          >
            Entregar
          </b-button>
        </div>
      </template>

    </b-modal>

    <!-- MODAL PARA EVALUACION-->
    <b-modal
        v-model="modalRubrica"
        persistent
        title="Quiz"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            Quiz de Actividad
          </b-col>
        </b-row>
        <b-row class="my-5" style="display: flex; justify-content: space-around; ">
          <!--<Rubrica :rubricaId='this.rubrica_id'/>-->
        </b-row>

      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalRubrica()">
            Cancelar
          </b-button>
        </div>
      </template>

    </b-modal>
    <!-- FIN MODAL PARA EVALUACION-->

    <!-- modal entregable  confirmacion -->
    <b-modal
        v-model="modalEntrega"
        persistent
        title=" Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            ¿ Estás seguro de realizar la entrega del siguiente archivo desde tu drive ?
          </b-col>
        </b-row>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            <hr>
            <b-card-text>
              Nombre:
              <b-img :src="file.iconUrl"></b-img>
              {{ file.name }}
              <br>
              Tipo: {{ file.type }}
            </b-card-text>

          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">

          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalConfirmacionEntrega()">
            Cancelar
          </b-button>

          <b-button
              variant="success"
              size="sm"
              class="float-right mx-2"
              @click="hacerEntrega()">
            ¡Hacer entrega!
          </b-button>

        </div>
      </template>

    </b-modal>

    <!-- modal rubrica eleccion -->
    <b-modal
        v-model="modalRubrica"
        persistent
        title=" Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            Criterios de evaluación
          </b-col>
        </b-row>
        <b-row class="my-5" style="display: flex; justify-content: space-around; ">
          <Rubrica :rubricaId='this.rubrica_id'/>
        </b-row>

      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalRubrica()">
            Cancelar
          </b-button>
        </div>
      </template>

    </b-modal>


    <!-- modal esperar a mostrar calificacion -->
    <b-modal
        v-model="modalQuiz"
        persistent
        title="Evaluación"
        size="lg"
        centered
        hide-footer
        no-close-on-esc
        hide-header-close
    >
      <!--        hide-footer="true"-->
      <b-container fluid>
        <b-row class="my-5" style="display: flex; justify-content: space-around; ">
          <Quiz :idUnidad="actividadIdQuiz"
                :modulo="10"

                :curricula="this.current_curso_curricula.id"
                :curso="this.currentCurso.id"
                :c_unidad="this.unidad"
                :objetivo="this.objetivo"

                :actividad="this.actividad"
                :material="this.material"
                :quiz="this.quiz"

                @modalQuiz="modalQuiz = $event"></Quiz>
        </b-row>
      </b-container>
      <template #modal-footer>
      </template>
    </b-modal>


    <!-- menu curricula>curso-->
    <b-row style="display: flex; justify-content: flex-start;">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/crear-contenido-curricula" class="">Currículas</router-link>
          </li>
          <li class="breadcrumb-item">
            <b-link @click="irCursosCurricula()">Cursos</b-link>
          </li>
        </ol>
      </nav>
    </b-row>

    <!--menu navegación entre cursos de hasta arriba-->
    <b-row class="mb-5"
           style="display: flex; justify-content: space-around; align-items:center; background-color:#14C6B9; border-radius: 10px; ">

      <div v-if="this.antCurso != null">
        <b-button @click="switchCursoPrev" variant="link" :disabled="!FormCurriculas.enabledHeaderNavegacion">
          <span style="color:white"> <b> Curso autogestivo anterior</b>  </span><br>
          <small> <i style="color:white"> {{ (this.antCurso.nombre_curso) ? (this.antCurso.nombre_curso) : '' }} </i>
          </small>
        </b-button>
      </div>
      <div v-else>
        <b-button variant="link" :disabled="!FormCurriculas.enabledHeaderNavegacion">
          <span style="color:white"> <b> Curso autogestivo  anterior</b>  </span><br>
          <small> <i style="color:white"> No hay </i> </small>
        </b-button>
      </div>
      <div>
        <b-button variant="link" :disabled="!FormCurriculas.enabledHeaderNavegacion">
          <span style="color:white"><b> Curso autogestivo actual </b>  </span> <br> <small><i
            style="color:white">{{ this.currentCurso.name }} </i> </small></b-button>
      </div>
      <div v-if="this.sigCurso != null">
        <b-button @click="switchCursoNext" variant="link" :disabled="!FormCurriculas.enabledHeaderNavegacion">
          <span style="color:white"><b> Curso autogestivo  siguiente  </b>  </span><br>
          <small> <i style="color:white"> {{ (this.sigCurso.nombre_curso) ? (this.sigCurso.nombre_curso) : '' }} </i>
          </small>
        </b-button>
      </div>
      <div v-else>
        <b-button variant="link" :disabled="!FormCurriculas.enabledHeaderNavegacion">
          <span style="color:white"> <b> Curso autogestivo  siguiente  </b>  </span><br>
          <small> <i style="color:white"> No hay </i> </small>
        </b-button>
      </div>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-alert v-model="FormCurriculas.visibleModalMensaje" variant="light" show>
          <b-button variant="primary" disabled>
            <b-spinner small type="grow"></b-spinner>
            {{FormCurriculas.mensaje_confirmacion}}
          </b-button>
        </b-alert>
        <!--          <Notificacion ref="notificacionModal"/>-->
      </b-col>
    </b-row>

    <!-- switch ver unidades y ver Dudas-->
    <b-row class="my-3" style="display: flex; justify-content: space-between;">
      <b-form-checkbox class="ml-3" @change="someHandler" v-model="checkUnidades"
                       :disabled="!FormCurriculas.enabledVerUnidades" name="check-button" switch>Ver unidades
      </b-form-checkbox>

      <!--    <button @click="colapsarAcordion">
            colapsar activo {{this.index_unidad_curso.toString()}}
          </button>-->

      <b-form-checkbox style="display: none; visibility: hidden" class="mr-3"
                       @change="someHandlerDudas" v-model="checkDudas" name="check-button" switch
                       :disabled="!FormCurriculas.enabledVerMateriales">
        Materiales
      </b-form-checkbox>
    </b-row>

    <b-row no-gutters>
      <!--- curriculas menú izquierdo no visible ni funcional-->
      <b-col cols="12" lg="2" md="2" sm="12" style="display:none">
        <div class="boxScroll">

          <div v-for="(curricula_navegacion, index) in curriculas_navegacion" :key="index">
            {{curricula_navegacion}}
            <div class="mb-4 mr-2">
              <small><i>Currícula:</i></small>
              <b-jumbotron class="text-left" :lead="curricula_navegacion.nombre_curricula"></b-jumbotron>
              <b-list-group>
                <div v-if="curricula_navegacion.cursos_curricula.length>0">
                  <small><i>Cursos:</i></small>
                  <div v-for="(curso, i) in curricula_navegacion['cursos_curricula'] " :key="i">
                    <div v-for="(test, k) in  curso.curso_curricula " :key="k">
                      <div v-if=" k=='nombre_curso' ">
                        <div v-if="curso.curso_id == currentCursoId">
                          <b-list-group-item active> {{ test }}</b-list-group-item>
                        </div>
                        <div v-else>
                          <b-list-group-item> {{ test }}</b-list-group-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <small><i>De momento, esta currícula no tiene cursos</i></small>
                </div>
              </b-list-group>
            </div>
          </div>
        </div><!-- box scroll -->
      </b-col>

      <!--main left section-->
      <b-col cols="12" lg="3" md="3" sm="12" class="collapse width" :class=" collaps ? 'show' : '' ">

        <div class="boxScroll">
          Progreso del curso:
          <b-progress :value="porcentaje_avance" max="100" variant="warning" class=" mr-2"></b-progress>
          <hr>
          <span style="display:none">
                {{ unidadVisible }}
            </span>

          <div class="my-2 mr-2"
               v-for="(unidad_curso,index_unidad_curso) in current_curso_curricula.unidades_curso"
               :key="index_unidad_curso">

            <b-button
                style="width:100%; background-color: #14C6B9 !important; border:0"
                class="btn-studioAPP collapsed"
                variant="outline-warning"
                v-b-toggle:index_unidad_curso.toString()
                @click="switchUnidad(index_unidad_curso)"
                :disabled="!FormCurriculas.enabledNavegacionUnidades"
            >
              <div class="text-left">
                <div v-text="`${index_unidad_curso + 1}. ${unidad_curso.unidad.nombre_unidad}`"></div>
              </div>
              <p class="text-justify">
                <small v-text="unidad_curso.unidad.descripcion"></small>
              </p>
            </b-button>

            <b-collapse :id="index_unidad_curso.toString()" accordion="my-accordion"
                        v-model="visible[index_unidad_curso]" class="mt-2">
              <b-card>
                <b>
                  <b-jumbotron lead="Objetivos"></b-jumbotron>
                </b>
                <b-list-group>

                  <div v-for="(objetivo_unidad,index_objetivo) in unidad_curso.unidad.objetivos_unidad"
                       :key="index_objetivo">
                    <b-list-group-item>

                      <a href="#" :disabled="!FormCurriculas.enabledEnlaces"
                         @click="verMaterialObjetivo(objetivo_unidad.objetivo)"
                         :class="((objetivo_unidad.active===1)?'primary--text':'')">
                        {{
                          `${index_unidad_curso + 1}.${index_objetivo + 1} ${objetivo_unidad.objetivo.nombre_objetivo}`
                        }}
                      </a>
                    </b-list-group-item>
                    <small v-if="objetivo_unidad.objetivo.materiales_objetivo.length>0" class="my-2">Materiales:</small>
                    <div class="ml-5" v-for="(material, index) in objetivo_unidad.objetivo.materiales_objetivo"
                         :key="index">

                      <a v-if="material.tipo_material_id === 3" :href="material.ruta_documento" target="_blank"> <small>{{
                          material.nombre_material
                        }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="ml-1 bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                          <path fill-rule="evenodd"
                                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                        </svg>
                      </small></a>

                      <a v-if="material.tipo_material_id === 4" :href="material.ruta_documento" target="_blank"> <small>{{
                          material.nombre_material
                        }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="ml-1 bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                          <path fill-rule="evenodd"
                                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                        </svg>
                      </small>
                      </a>
                      <!-- Si es un documento de Google Docs -->
                      <!--                <a v-else-if="material.tipo_material_id === 4" :href="material.ruta_documento" target="_blank">Abrir el material de apoyo</a>-->
                      <!-- Si es una imagen -->
                      <!--                <img v-else-if="material.tipo_material_id === 3" :src="material.ruta_documento" alt="Imagen alusiva">-->
                      <!-- Agrega más condiciones para otros tipos de materiales si es necesario -->
                    </div>
                    <hr>
                  </div>
                </b-list-group>


                <div v-if="actividades_unidad_actual.length>0">


                  <b-jumbotron lead="Actividades ⬇️"></b-jumbotron>
                  <hr>
                  <b-card>
                    <b-alert
                        class="p-2"
                        show
                        variant="warning"
                        dismissible>
                      <small>
                        Marca las actividades realizadas
                      </small>
                    </b-alert>

                    <div v-for="(actividad_unidad, index_actividad) in actividades_unidad_actual"
                         :key="index_actividad">
                      <div v-if="actividad_unidad.actividad_realizada > 0">
                        <div class="form-inline">
                          <div class="ml-1 actividad_checkbox p-2 my-2 shadow-sm"
                               style="overflow-y: auto;text-decoration-line: line-through; border:1px #dedede solid;  border-radius: 15px;">
                            <small>
                              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                                   class="bi bi-check-square" viewBox="0 0 16 16">
                                <path
                                    d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                <path
                                    d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                              </svg>
                              {{ actividad_unidad.detalles_actividad }}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div v-else
                           class="actividad_checkbox p-3 my-1 shadow-sm"
                           style="border:1px #dedede solid;  border-radius: 15px;">
                        <b-form-checkbox
                            class="my-1"
                            :disabled="( (actividad_unidad.actividad_realizada > 0) && FormCurriculas.enabledActividad )"
                            :v-model="( (actividad_unidad.actividad_realizada > 0) ? activado=1 : activado=0 )"
                            unchecked-value="0"
                            value="1"
                            @change="completarActividad(actividad_unidad)"
                        >
                          <!--text-break se quitó esta clase porque se retiraron enlances y no se ncesita cortar las palabras-->
                          <span class="" style="text-align:justify"> {{ actividad_unidad.detalles_actividad }}</span>
                          <p style="display:none">
                            actividad realizada -- {{ actividad_unidad.actividad_realizada }}
                          </p>
                          <!-- <small>
                               <a href="#!"
                                       v-if="(actividad_unidad.items_actividad.length > 0)"
                                   @click="openSubAcitivies(actividad_unidad)" class="ml-1" color="primary" small outlined>Detalles</a>
                           </small>-->
                        </b-form-checkbox>

                      </div><!--v-else-->

                      <div v-if="actividad_unidad.alumno_entrego==1">
                        <b-row>
                          <b-button variant="link" size="sm" class="my-1" type="button" disabled>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="21"
                                 viewBox="0 0 1443.061 1249.993">
                              <path fill="#3777e3" d="M240.525 1249.993l240.492-416.664h962.044l-240.514 416.664z"/>
                              <path fill="#ffcf63" d="M962.055 833.329h481.006L962.055 0H481.017z"/>
                              <path fill="#11a861" d="M0 833.329l240.525 416.664 481.006-833.328L481.017 0z"/>
                            </svg>
                            ¡Archivo entregado!
                          </b-button>
                        </b-row>
                        <b-row>
                          <b-button disabled variant="success" size="sm" class="mr-1">
                            Entregar
                          </b-button>
                        </b-row>
                      </div>

                      <div v-else-if="actividad_unidad.alumno_entrego==0">
                        <!-- 0 no entregable-->
                        <div v-if="(actividad_unidad.entregable > 0)" class="mt-2" style="text-align: center">
                          <hr>
                          <span>Acciones</span>
                          <b-row>
                            <div v-if=" actividad_unidad.actividad_rubrica != null ">
                              <b-button style="display: none;" variant="info" v-b-modal.modal-prevent-closing size="sm"
                                        @click="showModalRubrica(actividad_unidad.actividad_rubrica.id)" class="mr-1">
                                Ver rúbrica
                              </b-button>
                            </div>
                          </b-row>
                          <div style="text-align: center"
                               v-if="(actividad_unidad.entregable == 1 || actividad_unidad.entregable == 2 || actividad_unidad.entregable == 3)">
                            <b-button @click="showModalEntregaURL(actividad_unidad)" variant="primary" size="sm"
                                      class="mr-1 my-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                   class="bi bi-paperclip" viewBox="0 0 16 16">
                                <path
                                    d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
                              </svg>
                              Entregar
                            </b-button>

                            <b-row style="visibility: hidden;">
                              <GDriveSelector v-on:handlePickerFiles="getData"/>
                              <b-row style="text-align: center;">
                                <div v-if="file.id !=0 ">
                                  <b-button @click="showModalConfirmacionEntrega(actividad_unidad)" variant="success"
                                            size="sm" class="mr-1">
                                    Entregar
                                  </b-button>
                                </div>
                                <div v-else style="text-align: center; ">
                                  <b-button disabled variant="success" size="sm" class="mr-1">
                                    Entregar
                                  </b-button>
                                  <br>
                                  <span class="txt_attachmnts"> Hay que elegir un archivo del drive!</span>
                                </div>
                              </b-row>
                            </b-row>

                          </div>
                        </div>
                        <div v-else>
                          <!--<small> <i>No hay entregables</i></small>-->
                          <small> <i></i></small>
                        </div>
                      </div><!--v-else-if-->
                      <div style="display: none" v-if="actividad_unidad.quiz>0">
                        <span>Evaluaciones</span>
                        <hr>
                        <div style="text-align: center">

                          <b-button @click="showModalQuiz(actividad_unidad.id)" variant="success" size="sm"
                                    class="mr-1">
                            Iniciar
                          </b-button>
                        </div>
                      </div>

                      <div v-if="actividad_unidad.avanza_examen_id>0">
                        <hr>
                        <div style="text-align: center">

                          <b-link href="#" @click="examen_avanza(actividad_unidad.avanza_examen_id )" class="card-link">
                            Evaluación
                          </b-link>

                        </div>
                      </div>

                    </div><!--v-for-->
                  </b-card>

                </div>
              </b-card>
            </b-collapse>
          </div><!-- switch unidad-->
        </div><!--boxScroll-->
      </b-col>
      <!--main center section-->
      <b-col cols="12" :lg=" (!collaps && !collapsDudas) ? '12' : (collapsDudas==false ? '9':'7') " md="7" sm="12">
        <div class="box-scroll">
          <div v-if="this.current_objetivo">

            <div v-if="current_objetivo.video && current_objetivo.video.nombre && current_objetivo.video.liga !== null">
              <b-card
                  :title="current_objetivo.video.nombre"
                  img-top
                  tag="article"
                  class="mx-2"
              >
                <b-card-text>
                  <div style="text-align: center">
                    <video
                        ref="videoPlayer"
                        class="video-js vjs-default-skin"
                        preload="auto"
                        width="700"
                        height="420"
                        controls
                        @contextmenu.prevent
                        :disabled="!FormCurriculas.enabledVideo"
                    >
                      <source v-if="current_objetivo.video.liga" :src="current_objetivo.video.liga" type="video/mp4"/>
                    </video>
                  </div>
                </b-card-text>
              </b-card>
            </div>
            <div v-else>


              <b-jumbotron class="estilo_no_tiene_video" lead="Este objetivo no tiene video"></b-jumbotron>

            </div>


          </div><!--current_objetivo-->

        </div><!--box-scroll-->
        <div class="text-center mx-1">


          <div v-if=" this.current_objetivo ">
            <b-form-textarea
                v-model="duda_msj"
                id="textarea"
                placeholder="Déjanos tus dudas en esta sección..."
                rows="2"
                max-rows="3"
            >
            </b-form-textarea>
          </div>
          <div v-else>
            <b-form-textarea
                disabled
                placeholder="Déjanos tus dudas en esta sección..."
                rows="2"
                max-rows="3"
            >
            </b-form-textarea>
          </div>


          <div v-if="this.duda_msj">
            <b-button
                @click="guardarDuda"
                class="btn-studioAPP my-1 mx-5"
                size="sm">Enviar duda
            </b-button>
          </div>
          <div v-else>
            <b-button
                disabled
                class="btn-studioAPP my-1 mx-5"
                size="sm">Enviar duda
            </b-button>
          </div>
          <hr>
          <div v-if="dudas_ya_mostradas==true">
            <div v-if="dudas.length>0">
              <div v-for="(duda, index ) in dudas" :key="index">
                <div class="container text-right">
                  <p><small> {{ duda.duda_msj }}</small></p>
                </div>
                <div v-if="duda[`dudas_respuesta`]!=null">
                  <div class="container darker">
                    <p><small> {{ duda[`dudas_respuesta`].respuesta_duda_msj }} </small></p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="alert alert-warning" role="alert">
                De momento no se cuentan con dudas
              </div>
            </div>
          </div>
          <div v-else>
            <div class="alert alert-warning" role="alert">
              Para visualizar el apartado de dudas, hay que elegir un objetivo
            </div>
          </div>

        </div>
      </b-col>

      <!-- dudas chat-->
      <b-col v-if="collapsDudas===true" cols="12" :lg=" (!collaps) ? '4' : '2' " md="7" sm="12"
             :class=" (collapsDudas ) ? '' : 'd-none' ">


        <div v-if="!current_objetivo">


        </div><!--v-if="!current_objetivo"-->
        <div v-else>
          <b-card
              title=""
              img-top
              tag="article"
              class="mx-2"
          >
            <b-card-text>
              <div v-for="(material, index) in current_objetivo.materiales_objetivo" :key="index" style="display: none">


                <a v-if="material.tipo_material_id === 3" :href="material.ruta_documento" target="_blank">
                  <small>{{ material.nombre_material }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="ml-1 bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                      <path fill-rule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                    </svg>
                  </small></a>

                <a v-if="material.tipo_material_id === 4" :href="material.ruta_documento" target="_blank">
                  <small>{{ material.nombre_material }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="ml-1 bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                      <path fill-rule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                    </svg>
                  </small></a>
                <!-- Si es un documento de Google Docs -->
                <!--                <a v-else-if="material.tipo_material_id === 4" :href="material.ruta_documento" target="_blank">Abrir el material de apoyo</a>-->
                <!-- Si es una imagen -->
                <!--                <img v-else-if="material.tipo_material_id === 3" :src="material.ruta_documento" alt="Imagen alusiva">-->
                <!-- Agrega más condiciones para otros tipos de materiales si es necesario -->
              </div>
            </b-card-text>
          </b-card>
        </div>

        <div class="text-center mx-1" style="visibility: hidden">

          <div v-if=" this.current_objetivo ">
            <b-form-textarea
                v-model="duda_msj"
                id="textarea"
                placeholder="Déjanos tus dudas en esta sección..."
                rows="2"
                max-rows="3"
            >
            </b-form-textarea>
          </div>
          <div v-else>
            <b-form-textarea
                disabled
                placeholder="Déjanos tus dudas en esta sección..."
                rows="2"
                max-rows="3"
            >
            </b-form-textarea>
          </div>


          <div v-if="this.duda_msj">
            <b-button
                @click="guardarDuda"
                class="btn-studioAPP my-1 mx-5"
                size="sm">Enviar duda
            </b-button>
          </div>
          <div v-else>
            <b-button
                disabled
                class="btn-studioAPP my-1 mx-5"
                size="sm">Enviar duda
            </b-button>
          </div>
          <hr>
          <div v-if="dudas_ya_mostradas==true">
            <div v-if="dudas.length>0">
              <div v-for="(duda, index ) in dudas" :key="index">
                <div class="container text-right">
                  <p><small> {{ duda.duda_msj }}</small></p>
                </div>
                <div v-if="duda[`dudas_respuesta`]!=null">
                  <div class="container darker" style="margin-left:35px !important; "
                       :style=" collaps ? 'width:170px' : 'width:382px' ">
                    <p><small> {{ duda[`dudas_respuesta`].respuesta_duda_msj }} </small></p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="alert alert-warning" role="alert">
                De momento no se cuentan con dudas
              </div>
            </div>
          </div>
          <div v-else>
            <div class="alert alert-warning" role="alert">
              Para visualizar el apartado de dudas, hay que elegir un objetivo
            </div>
          </div>

        </div>

      </b-col>

    </b-row>

  </b-container>
</template>

<script>

import videojs from 'video.js';
import 'video.js/dist/video-js.css';

//import ExamenNivelando from '@/services/ExamenNivelando';
import Bugsnag from '@bugsnag/js';
import GDriveSelector from "../../components/GDriveSelector";
import EntregableService from '@/services/EntregableService';
import Rubrica from '@/components/Rubricas/Rubrica';
import toast from "@/mixins/ToastMixin.js";
import {getIdFromURL} from "vue-youtube-embed";
import Quiz from "@/components/Quiz/Quiz.vue";
import AvanzaToken from "../../services/AvanzaToken";
//import CursoService from "../../services/CursoService";
export default {
  mixins: [toast],
  mounted() {
    // this.player = videojs(this.$refs.videoPlayer);
    // this.player.on('play', this.onPlay);

    this.current_curricula = this.$route.params.curricula;
    // this.current_curso_curricula = this.$route.params.curso;
    this.oferta_actual = this.$route.params.oferta_actual;

    if (this.$route.params.curricula == undefined) {
      if (localStorage.getItem('current_curricula')) {
        try {
          this.current_curricula = JSON.parse(localStorage.getItem('current_curricula'));
        } catch (e) {
          localStorage.removeItem('current_curricula');
        }
      }
    } else {
      const parsed = JSON.stringify(this.current_curricula);
      localStorage.setItem('current_curricula', parsed);
    }


    /*MOVER ACA*/
    if (this.$route.params.curso == undefined) {
      if (localStorage.getItem('current_curso_curricula')) {
        try {
          this.current_curso_curricula = JSON.parse(localStorage.getItem('current_curso_curricula'));
        } catch (e) {
          localStorage.removeItem('current_curso_curricula');
        }
      }
    } else {
      this.getContenido(this.$route.params.curso.id);
      const parsed2 = JSON.stringify(this.current_curso_curricula);
      localStorage.setItem('current_curso_curricula', parsed2);
    }
    /*MOVER ACA*/


    if (this.$route.params.oferta_actual == undefined) {
      if (localStorage.getItem('oferta_actual')) {
        try {
          this.oferta_actual = JSON.parse(localStorage.getItem('oferta_actual'));
        } catch (e) {
          localStorage.removeItem('oferta_actual');
        }
      }
    } else {
      const parsed3 = JSON.stringify(this.oferta_actual);
      localStorage.setItem('oferta_actual', parsed3);
    }

    /*              console.log("this.$route.params.curso.id");
                  console.log(this.$route.params.curso.id);

                    console.log( "this.$route.params.curricula.id" );
                    console.log( this.$route.params.curricula.id );*/

    //  this.currentCurso.name      = this.current_curso_curricula.nombre_curso;  //Introducción a Google Workspace for Education

    this.getCursosCurricula(this.$route.params.curricula.id);
    this.logLiveEvents(10, 1);
/*    console.log(">>>>>>>>>MOUNTED>>>>>>>>>>>>>>>>")
    console.log("current_curso_curricula")
    console.log(this.current_curso_curricula)
    console.log("this.ANTERIORCURSO.id")
    console.log(this.antCurso)

    console.log("this.ACTUALCURSO.id")
    console.log(this.currentCurso)

    console.log("this.SIGUIENTECURSO.id")
    console.log(this.sigCurso)*/
  },
  components: {
    Rubrica,
    GDriveSelector,
    Quiz
  },
  data() {
    return {
      FormCurriculas: {
        visibleModalMensaje: false,
        mensaje_confirmacion: null,
        enabledHeaderNavegacion: false,
        enabledMenuNavegacion: false,
        enabledVerUnidades: false,
        enabledVerMateriales: false,
        enabledNavegacionUnidades: false,
        enabledEnlaces: false,
        enabledActividad: false,
        enabledVideo: false,
      },
      index_unidad_curso: 0,
      muteado: false,
      corriendo: false,
      presentarExam: false,
      modalEntrega: false,
      file: {
        name: null,
        url: null,
        id: 0,
        type: null,
        iconUrl: '',
        unidad: 0,   ///para efectos de guardarlos en la entrega
        actividad: 0 ///para efectos de guardarlos en la entrega
      },
      modalRubrica: false,
      modalQuiz: false,
      rubrica_id: 0,
      dudas_ya_mostradas: false,
      dudas: [],
      duda_msj: '',

      //cambiados a false para ocultar el switch y la sección
      collapsDudas: false,
      checkDudas: false,

      checkUnidades: true,
      checked: null,
      collaps: true,
      currentCursoId: 2,
      contador: 0,

      cursos_current_curricula: [],

      prevCurso: {
        name: null,
        id: 0,
      },

      currentCurso: {
        name: null,
        id: 0,
      },

      nextCurso: {
        name: null,
        id: 0,
      },

      antCurso: null,
      sigCurso: null,

      oferta_actual: [],
      activado: false,
      porcentaje_avance: 0,

      //locally to compare
      current_objetivo: null,
      current_unidad: null,
      current_actividad: null,
      current_material: null,
      current_quiz_id: null,

      //to send another components
      //curricula
      //curso

      objetivo: 0,
      unidad: 0,
      actividad: 0,
      material: 0,
      quiz: 0,

      current_page_objetivo: 0,
      actividades_unidad_actual: [],
      current_curso_curricula: [],

      actividadUnidad: [],
      curriculas_navegacion: [],
      progressBarCurrentValue: 15,
      progressBarMaxValue: 100,

      visible: [false, false, false, false, false],
      unidadVisible: false,
      actividadIdQuiz: 0,
      ModalEntregaURL: false,
      URLaEntregar: "",
      iframeSrc: null,

      currentTime: 0,
      duration: 0,
      videoDuration: 0,
      /*  muteado:false,
        corriendo:false,*/
      player: null,
      url: 'https://storage.googleapis.com/aprende_videos/omnibox.mp4?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=nivelandoaprenderun%40estudioapp2.iam.gserviceaccount.com%2F20230806%2Fauto%2Fstorage%2Fgoog4_r\n' +
          'equest&X-Goog-Date=20230806T064153Z&X-Goog-Expires=518400&X-Goog-SignedHeaders=host&X-Goog-Signature=338f6f031a9da988c3be4435aeb25252496482d5bdd07ad0e487a043cabda0669bca943b5066a81a81237f377103720567f9516c\n' +
          'd99a1d9770dcdcde30fff6a5160aff8280784a8425e8bf737645e63e8e974d773c5039c153917946b7232f8699c0b6c81a7083c30af7eda20bbd1bf6a3b1c4e3fe16a70f1e6e36fb90e70e55b1a4bed4576e1d31c96905ff69130430158d97534fdbc463193c5\n' +
          '49ba5f33851f0ebb1f62eb76504ee3335e07c06e5c146bcd35dde80f0d77c781903f5ed8a0eeec897110115930ea24dd93b07cc81f4ac23435e3588e706661784ef1d2c4017387c5c6e4fc1f7d48127c55ed5206bfb8692287dc079b1d7333ba205379e5a0a'

    }
  },
  watch: {
    current_objetivo: {
      handler(newValue) {
        this.player.src(newValue.video.liga);
        this.player.load();
        // this.player.play();
      },
      deep: true
    }
  },
  methods: {
    onPlay() {
      //console.log('corriendo video');
      this.logLiveEvents(10, 6);
    },
    async examen_avanza(examen) {
      this.current_quiz_id = examen;
      await this.logLiveEvents(10, 26);
      this.current_quiz_id = 0;
      const response = await AvanzaToken.getToken();
      let {token} = response.data;
      const url = `https://avanza.estudio.app/examenv2/${examen}/${token}`;
      //  const url = `${process.env.VUE_APP_URL_EXAMEN_AVANZA}/examenv2/${examen}/${token}`;
      window.open(url, '_blank');
    },
    async getContenido(curso_id) {

      const response = await this.$api.get(`/api/contenidos/pantallaAutogestivo/${curso_id}`);
      let {
        resultSet,
        success,
      } = response.data;
      if (success) {
        this.current_curso_curricula = resultSet;
        this.currentCurso.name = this.current_curso_curricula.nombre_curso;
        this.currentCurso.id = this.current_curso_curricula.id;  //aca curso_id
      }
    },
    even(arr) {
      //re acomodo en base a position
      return arr.slice().sort(function (a, b) {
        return a.position - b.position;
      });
    },
    botonAbrirMaterial(material) {
      this.current_material = material;
      this.logLiveEvents(10, 25);
    },
    async logLiveEvents(modulo, accion) {

      this.unidad = (this.current_unidad != null) ? this.current_unidad.id : 0;
      this.objetivo = (this.current_objetivo != null) ? this.current_objetivo.id : 0;
      this.actividad = (this.current_actividad != null) ? this.current_actividad : 0;
      this.material = (this.current_material != null) ? this.current_material.id : 0;
      this.quiz = (this.current_quiz_id != null) ? this.current_quiz_id : 0;

      await this.$api.get(`/api/log/${modulo}/${accion}/${this.current_curricula.id}/${this.currentCurso.id}/${this.unidad}/${this.objetivo}/${this.actividad}/${this.material}/${this.quiz}`);

      this.current_actividad = null;
      this.current_material = null;
      this.current_quiz_id = null;
    },
    colapsarAcordion() {
      //console.log('colapsarAcordion');
      this.$root.$emit('bv::toggle::collapse', this.index_unidad_curso.toString());
    },
    showModalQuiz(id) {
      this.modalQuiz = true;
      this.actividadIdQuiz = id;
      this.current_quiz_id = id;
      // this.logLiveEvents(10, 26);
    },
    /*    async mutear(){
          this.muteado=true;
          await this.player.mute();
          await this.logLiveEvents(10, 11);
        },
        async subirVol(){
          let vol = await this.player.getVolume();
          this.player.setVolume(vol+20);
          if( this.player.isMuted() ){
            await this.player.unMute();
            this.muteado=false;
          }
          await this.logLiveEvents(10, 13);
        },
        async bajarVol(){
          let vol = await this.player.getVolume();
          this.player.setVolume(vol-20);
          let vol2 = await this.player.getVolume();
          if(vol2==0){
            this.muteado=true;
          }
          await this.logLiveEvents(10, 14);
        },
        async desMutear(){
          this.muteado=false;
          await this.player.unMute();
          await this.logLiveEvents(10, 12);
        },
        async customStop(){
          this.player.stopVideo();
          this.player.seekTo(0);
          await this.logLiveEvents(10, 8);
        },
        async atrasar(){
          let time = await this.player.getCurrentTime();
          this.player.seekTo(time-15);
          await this.logLiveEvents(10, 9);
        },
        async adelantar(){
          let time = await this.player.getCurrentTime();
          this.player.seekTo(time+15);
          await this.logLiveEvents(10, 10);
        },
        ready (event) {
          this.player = event.target;
        },
        playing (event) {
          console.log("playing event..." + event);
        },
        stop() {
          this.corriendo=false;
          this.player.stopVideo();
          this.logLiveEvents(10,8);
        },
        pause() {
          this.corriendo=false;
          this.player.pauseVideo();
          this.logLiveEvents(10,7);
        },
        async playVideo() {
          this.corriendo=true;
          await this.player.playVideo();
          await this.logLiveEvents(10, 6);
        },*/
    irCursosCurricula() {
      this.$router.push({
        name: 'CursosCurricula',
        params: {curricula: this.$route.params.curricula, is_dashboard: true}
      });
    },
    async hacerEntrega() {
      this.modalEntrega = false;
      this.toast('b-toaster-bottom-center', true, "Se está haciendo la entrega...", "info");
      let formData = new FormData();
      formData.append("curricula_id", this.$route.params.curricula.id);
      formData.append("curso_id", this.current_curso_curricula.id);     //aca
      formData.append("unidad_id", this.file.unidad);
      formData.append("actividad_id", this.file.actividad);
      formData.append("file_id", this.file.id);
      formData.append("file_name", this.file.name);
      formData.append("file_url", this.file.url);
      formData.append("file_type", this.file.type);
      formData.append("file_iconUrl", this.file.iconUrl);
      try {
        const response = await EntregableService.saveEntregable(formData);
        let {
          success,
          message,
          color
        } = response.data;
        if (success) {
          this.file.name = null;
          this.file.url = null;
          this.file.id = 0;
          this.file.type = null;
          this.file.iconUrl = null;

          this.toast('b-toaster-bottom-center', true, message, color);
          await this.logLiveEvents(10, 21);
        }
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger");
        Bugsnag.notify(
            new Error('Método: hacerEntrega() ' + error)
        );
      }

    },
    async entregarURL() {
      this.hideModalEntregaURL();
      let formData = new FormData();
      formData.append("curricula_id", this.$route.params.curricula.id);
      formData.append("curso_id", this.current_curso_curricula.id);     //aca
      formData.append("unidad_id", this.unidad);
      formData.append("actividad_id", this.actividad);
      formData.append("url", this.URLaEntregar);

      try {
        const response = await EntregableService.saveEntregableURL(formData);
        let {
          success,
          message,
          color
        } = response.data;
        if (success) {
          this.toast('b-toaster-bottom-center', true, message, color);
          await this.logLiveEvents(10, 31);
          this.URLaEntregar = "";
        }
      } catch (error) {
        this.toast('b-toaster-bottom-center', true, 'Ocurrió un error, favor de comunicarse con su administrador', "danger");
        Bugsnag.notify(
            new Error('Método: entregarURL() ' + error)
        );
      }


    },
    hideModalEntregaURL() {
      this.ModalEntregaURL = false;
    },
    showModalEntregaURL(actividad_unidad) {

      this.unidad = actividad_unidad.unidad_id;
      this.actividad = actividad_unidad.id;
      this.ModalEntregaURL = true;
    },
    showModalConfirmacionEntrega(actividad_unidad) {
      this.file.unidad = actividad_unidad.unidad_id;
      this.file.actividad = actividad_unidad.id;
      this.current_actividad = this.file.actividad;
      this.modalEntrega = true;
      this.logLiveEvents(10, 29);
    },
    hideModalConfirmacionEntrega() {
      this.modalEntrega = false;
      this.logLiveEvents(10, 30);
    },
    getData(data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty("docs")) {
        this.file.id = data.docs[0].id;
        this.file.name = data.docs[0].name;
        this.file.url = data.docs[0].url;
        this.file.type = data.docs[0].type;
        this.file.iconUrl = data.docs[0].iconUrl;
      }
    },
    hideModalRubrica() {
      this.modalRubrica = false;
    },
    showModalRubrica(rubrica_id) {
      this.rubrica_id = rubrica_id;
      this.modalRubrica = true;
      this.logLiveEvents(10, 18);
    },
    async getDudasObjetivo(item) {
      const response = await this.$api.get(`/api/contenidos/dudas/get/${item}`);
      let {
        dudas,
        success,
      } = response.data;
      if (success) {
        this.dudas_ya_mostradas = true;
        this.dudas = dudas;
      }
    },
    async guardarDuda() {

      let formData = new FormData();
      formData.append("curricula_id", this.current_curricula.id);
      formData.append("curso_id", this.currentCurso.id);
      formData.append("unidad_id", this.current_unidad.id);
      formData.append("objetivo_id", this.current_objetivo.id);
      formData.append("duda_msj", this.duda_msj);

      let url = `/api/contenidos/dudas/store`;
      const response = await this.$api.post(url, formData, {headers: {'content-type': 'multipart/form-data'}});
      let {
        success,
        message,
        color
      } = response.data;
      if (success == true) {
        this.toast('b-toaster-bottom-center', true, message, color);
        this.duda_msj = '';
        await this.getDudasObjetivo(this.current_objetivo.id);
        await this.logLiveEvents(10, 22);
      }
    },
    async switchCursoPrev() {

      this.muteado = false;
      this.corriendo = false;
      this.current_objetivo = null;

/*      this.FormCurriculas.mensaje_confirmacion="Favor de esperar, estamos actualizando el curso seleccionado"
      this.FormCurriculas.visibleModalMensaje = true
      this.disabledFormularioCurricula()*/
      await this.progresoCursoAlumno();

      this.colapsarAcordion();
      this.current_curso_curricula = this.antCurso;
      this.currentCurso.name = this.antCurso.nombre_curso;
      this.currentCurso.id = this.antCurso.id;
      await this.getCursosCurricula(this.current_curricula.id);
      this.dudas = [];
      await this.logLiveEvents(10, 24);
/*      this.enabledFormularioCurricula()
      this.FormCurriculas.visibleModalMensaje = false*/
    },
    async switchCursoNext() {
      this.muteado = false;
      this.corriendo = false;
      this.current_objetivo = null;
      this.colapsarAcordion();
/*      this.FormCurriculas.mensaje_confirmacion="Favor de esperar, estamos actualizando el curso seleccionado"
      this.FormCurriculas.visibleModalMensaje = true
      this.disabledFormularioCurricula()*/
      await this.progresoCursoAlumno();

      this.current_curso_curricula = this.sigCurso;
      this.currentCurso.name = this.sigCurso.nombre_curso;
      this.currentCurso.id = this.sigCurso.id;
      await this.getCursosCurricula(this.current_curricula.id);
      //await this.getContenido(this.current_curricula.id); //no acomoda la parte superior de navegación


      this.dudas = [];
      await this.logLiveEvents(10, 23);
/*      this.enabledFormularioCurricula()
      this.FormCurriculas.visibleModalMensaje = false*/
    },
    //arma la barra de navegación morada del auto gestivo arriba
    disabledFormularioCurricula(){
      this.FormCurriculas.enabledHeaderNavegacion = false
      this.FormCurriculas.enabledVerUnidades = false
      this.FormCurriculas.enabledVerMateriales = false
      this.FormCurriculas.enabledNavegacionUnidades = false
      this.FormCurriculas.enabledEnlaces = false
      this.FormCurriculas.enabledActividad = false
      this.FormCurriculas.enabledVideo = false
    },
    enabledFormularioCurricula(){
      this.FormCurriculas.enabledHeaderNavegacion = true
      this.FormCurriculas.enabledVerUnidades = true
      this.FormCurriculas.enabledVerMateriales = true
      this.FormCurriculas.enabledNavegacionUnidades = true
      this.FormCurriculas.enabledEnlaces = true
      this.FormCurriculas.enabledActividad = true
      this.FormCurriculas.enabledVideo = true
    },
    async getCursosCurricula(item) {
      this.FormCurriculas.visibleModalMensaje = true
      this.disabledFormularioCurricula()
      this.FormCurriculas.mensaje_confirmacion = "Favor de esperar un momento, estamos preparando tu espacio de trabajo..."
      const response = await this.$api.get(`/api/contenidos/curriculas/cursos/autogestivo/nav/${item}`);
      this.enabledFormularioCurricula()
      this.FormCurriculas.visibleModalMensaje = false

      //      const response = await this.$api.get(`/api/contenidos/curriculas/cursos/${item}/vivo/0`);
      let {
        cursos_curricula,
        success,
      } = response.data;
      if (success) {
        this.cursos_current_curricula = cursos_curricula;
        this.cursos_current_curricula = this.even(this.cursos_current_curricula);
        for (let i = 0; i < this.cursos_current_curricula.length; i++) {

          if (this.currentCurso.id == this.cursos_current_curricula[i].id) {

            if (i != 0) {
              this.antCurso = this.cursos_current_curricula[i - 1];

              this.prevCurso.name = this.cursos_current_curricula[i - 1].nombre_curso;
              this.prevCurso.id = this.cursos_current_curricula[i - 1].id;
            }

            if (i == 0) {
              this.antCurso = null;
              this.prevCurso.name = null;
              this.prevCurso.id = null;
            }

            if (i != this.cursos_current_curricula.length) {

              this.sigCurso = this.cursos_current_curricula[i + 1];

              this.nextCurso.name = this.cursos_current_curricula[i + 1].nombre_curso;
              this.nextCurso.id = this.cursos_current_curricula[i + 1].id;
            }

            if (i == this.cursos_current_curricula.length) {

              this.sigCurso = null;
              this.nextCurso.name = null;
              this.nextCurso.id = null;
            }

          }

        }

        /*       console.log(">>>>>>>>>>>>>>>>desde getCursosCurricula>>>>>>>>>>>>>>>>>>>")
               console.log("current_curso_curricula")
               console.log(this.current_curso_curricula)
               console.log("this.ANTERIORCURSO.id")
               console.log(this.antCurso.id)

               console.log("this.ACTUALCURSO.id")
               console.log(this.currentCurso)

               console.log("this.SIGUIENTECURSO.id")
               console.log(this.sigCurso.id)*/

      }

    },
    someHandlerDudas() {
      this.collapsDudas = !this.collapsDudas;
    },
    someHandler() {
      this.collaps = !this.collaps;
    },
    /*        openSubAcitivies(activity = []) {
                    this.current_activity.title = activity.detalles_actividad;
                    this.current_activity.items = activity.items_actividad;
                },*/
    async switchUnidad(index_unidad_curso) {
/*      console.log("<<<<<<<<<<<<<<< switchUnidad <<<<")
      console.log(index_unidad_curso)
      console.log("current_curso_curricula")
      console.log(this.current_curso_curricula)
      console.log("this.ANTERIORCURSO.id")
      console.log(this.antCurso.id)

      console.log("this.ACTUALCURSO.id")
      console.log(this.currentCurso.id)

      console.log("this.SIGUIENTECURSO.id")
      console.log(this.sigCurso.id)*/


      this.muteado = false;
      this.corriendo = false;
      this.index_unidad_curso = index_unidad_curso;//para acordion
      this.unidadVisible = !this.unidadVisible;
      this.visible[index_unidad_curso] = !this.visible[index_unidad_curso];

      try {
        this.actividades_unidad_actual = []
        this.current_unidad = this.current_curso_curricula.unidades_curso[index_unidad_curso].unidad;
        this.current_objetivo = this.current_curso_curricula.unidades_curso[index_unidad_curso].unidad.objetivos_unidad[0].objetivo;

/*        console.log("this.current_unidad")
        console.log(this.current_unidad)
        console.log("this.current_objetivo")
        console.log(this.current_objetivo)*/

        await this.progresoCursoAlumno();
        await this.logLiveEvents(10, 16);


        /*                 console.log("this.current_objetivo");
                          console.log(this.current_objetivo);*/
        this.player = videojs(this.$refs.videoPlayer);
        this.player.load();
        this.player.on('play', this.onPlay);

      } catch (e) {
        console.log(e)
      }
    },
    async completarActividad(actividad = []) {
      /* event.preventDefault()*/
      this.FormCurriculas.enabledActividad = false
      let formData = new FormData();
      formData.append("actividad_id", actividad.id);
      this.current_actividad = actividad.id;
      formData.append("curso_id", this.current_curso_curricula.id);   //aca
      formData.append("curricula_id", this.current_curricula.id);
      formData.append("unidad_id", this.current_unidad.id);
      let url = `/api/contenidos/progreso-alumno`;
      const response = await this.$api.post(url, formData, {headers: {'content-type': 'multipart/form-data'}});
      console.log(response.data + "");
      await this.progresoCursoAlumno();
      this.FormCurriculas.enabledActividad = true
      await this.logLiveEvents(10, 17);
    },
    async progresoCursoAlumno() {
      let formData = new FormData();
      formData.append("curricula_id", this.current_curricula.id);
      formData.append("curso_id", this.current_curso_curricula.id); //aca
      formData.append("unidad_id", this.current_unidad.id);

      //ACA
      let url = `/api/contenidos/progreso-curso-alumno`;
      const res = await this.$api.post(url, formData, {headers: {'content-type': 'multipart/form-data'}});
      this.porcentaje_avance = res.data.porcentaje_avance;
      this.numero_progresos_por_terminar = res.data;
/*      console.log("progresoCursoAlumno")
      console.log("<<<<<<<     UNIDADES    >>>>>>>")
      console.log("this.current_curricula.id")
      console.log(this.current_curricula.id)
      console.log("this.current_curso_curricula.id")
      console.log(this.current_curso_curricula.id)
      console.log("this.current_unidad.id")
      console.log(this.current_unidad.id)
      console.log(res.data.unidades)*/
      this.actividades_unidad_actual = res.data.unidades;

      //  console.log( this.actividades_unidad_actual );
    },
    anteriorMaterial() {
      this.muteado = false;
      this.corriendo = false;
      this.current_page_objetivo--
    },
    siguienteMaterial() {
      this.muteado = false;
      this.corriendo = false;
      this.current_page_objetivo++
    },
    verMaterialObjetivo(objetivo) {
      this.muteado = false;
      this.corriendo = false;
      this.current_objetivo = objetivo;
      this.getDudasObjetivo(this.current_objetivo.id);
      this.logLiveEvents(10, 15);
      this.player = videojs(this.$refs.videoPlayer);
      this.player.load();
      this.player.on('play', this.onPlay);
    },
    //tentativamente no hace nada, revisar ya que se fusionó con el switchUnidad
    mostrarOpciones(item) {
      //  console.log('method mostrarOpciones fired!');
      this.unidadVisible = !this.unidadVisible;
      this.visible[item] = !this.visible[item];
    },
    async curriculasNavegacion() {
      const response = await this.$api.get(`/api/contenidos/curriculasNavegacion`);
      let {
        message,
        color,
        success,
        curriculas_navegacion,
      } = response.data;
      if (success) {
        this.curriculas_navegacion = curriculas_navegacion;
      }
      this.toast('b-toaster-bottom-center', true, message, color);
    },
    getEmbedYoutubeUrl(youtube_url = '') {
      let string = youtube_url.split('https://www.youtube.com/watch?v=')
      return 'https://www.youtube.com/embed/' + string[1]
    },
    getCurrentVideoId(youtube_url) {
      return getIdFromURL(youtube_url)
    },
  },//methods


}
</script>


<style scoped>
/* mouse over link */
.actividad_checkbox:hover {
  background-color: #ecf0f1;
  transition: all .5s ease;
}

.container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.darker {
  border-color: #ccc;
  background-color: #ddd;
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.container img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

.container img.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

.time-right {
  float: right;
  color: #aaa;
}

.time-left {
  float: left;
  color: #999;
}

.txt_attachmnts {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10px !important;
  font-weight: 400;

}

/* collaps*/


.collapse {
  visibility: hidden;
}

.collapse.show {
  visibility: visible;
  display: block;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

.collapsing.width {
  -webkit-transition-property: width, visibility;
  transition-property: width, visibility;
  width: 0;
  height: auto;
}

/* collaps*/
</style>


<style lang="scss" scoped>

$studioAPPColor: #FF8250;

.boxScroll {
  height: 800px;
  overflow-y: scroll;
}

.list-group-item.active {
  background-color: $studioAPPColor !important;
  border-color: $studioAPPColor !important;
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  padding-left: 0 !important;

}

.content-page {
  margin-left: 0px !important;
  padding: 100px 0px 0px !important;

}

.btn-studioAPP {
  color: white !important;
  border: 1px solid $studioAPPColor;
  background-color: $studioAPPColor !important;
}

.btn-studioAPP:hover, .btn-studioAPP:active {
  background-color: $studioAPPColor !important;
  color: white !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* default*/

.estilo_no_tiene_video {
  padding: 15px;
  margin: 15px;
  background-color: white;
  display: flex;
  align-items: center;
  height: 300px;
  justify-content: center;
  border: 1px solid #dedede;
  border-radius: 10px;
}

</style>